import { Controller } from "@hotwired/stimulus"

// The element should have following attributes:
// data-controller="html--display_field"  -> To define the controller, must be on the element
// data-value -> the value to trigger
// data-element-id -> The id of the element to display or hide
// data-action="change->html--display-field#toggleDisplay" -> The event to trigger the toggle

export default class extends Controller {
  toggleDisplay() {
    if(this.element.value === this.element.getAttribute('data-value')) {
      document.getElementById(this.element.getAttribute('data-element-id')).classList.remove('hidden');
      // Focus text field
      document.getElementById(this.element.getAttribute('data-element-id')).getElementsByTagName('input')[0].focus();
    } else {
      document.getElementById(this.element.getAttribute('data-element-id')).classList.add('hidden');
      // Empty the first child inpu
      document.getElementById(this.element.getAttribute('data-element-id')).getElementsByTagName('input')[0].value = null;
    }
  }
}